<template>
  <not-found v-if="displayNotFound" />
  <div v-else class="grid-page">
    <pwa-prompt-ios />
    <z-menu class="menu" />
    <div class="grid-content">
      <z-menu-dropdown-cities
        v-if="showCitiesDropdown"
        v-model="selectedCity"
        class="d-md-none mb-3"
      />
      <router-view />
    </div>
    <notifications group="notifications" />
  </div>
</template>

<script>
import ClientType from "@zubut/common/src/constants/clients/type";
import ZMenu from "@/app/components/ZMenu";
import ZMenuDropdownCities from "@/app/components/ZMenuDropdownCities";
import * as mutation from "@/store/modules/cities/mutations-types";
import PwaPromptIos from "@/app/components/PwaPromptIos";
const NotFound = () => import("@/app/views/NotFound.vue");

export default {
  name: "Layout",

  components: {
    PwaPromptIos,
    ZMenu,
    ZMenuDropdownCities,
    NotFound
  },

  computed: {
    clientType() {
      return this.$store.state.user.type;
    },
    showCitiesDropdown() {
      return this.clientType === ClientType.NUM_COMPANY;
    },
    selectedCity: {
      get() {
        return this.$store.state.cities.selectedCity;
      },
      set(val) {
        this.$store.commit(`cities/${mutation.SET_SELECTED_CITY}`, val);
      }
    },
    displayNotFound() {
      return this.$store.state.displayNotFound;
    }
  }
};
</script>

<style lang="scss" scoped>
.grid-page {
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 60px 1fr;
  background-color: $ghost-white;
}

// Mobile first
.menu {
  grid-column: 1 / -1;
  grid-row: 1 / 2;
}

.grid-content {
  grid-column: 1 / -1;
  grid-row: 2 / -1;
  position: relative;
  width: 100%;
  padding: 1rem;

  .card {
    border: none;
    border-radius: 8px;
    margin: 0;
    border: 1px solid $gray-200;
  }
}

@include media-breakpoint-up(md) {
  .grid-content {
    padding: 0rem 2rem;
  }

  .grid-page {
    grid-template-rows: 100px 1fr;
  }
}

@include media-breakpoint-up(lg) {
  .menu {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }

  .grid-content {
    padding: 0rem 2rem 4rem 2rem;
    grid-column: 1 / -1;
    grid-row: 2 / -1;
  }
}
</style>
