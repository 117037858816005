<template>
  <div>
    <b-dropdown no-caret @show="getNotifications">
      <template #button-content>
        <div class="notification-dropdown">
          <div
            class="notification-dropdown-icon"
            :class="{ active: hasNewNotifications }"
          >
            <z-icon name="Notification" />
          </div>
        </div>
      </template>
      <div
        class="d-flex justify-content-between align-items-center container py-2"
      >
        <div class="font-weight-bold ">Notificaciones</div>
        <z-button
          v-if="!areNotifications"
          variant="secondary"
          @click="eraseNotifications"
        >
          Borrar Todo
        </z-button>
      </div>
      <div v-if="loading">
        <loading-spinner />
      </div>
      <div v-else>
        <div v-if="areNotifications" class="text-center py-2">
          No hay notificaciones para mostrar
        </div>
        <b-dropdown-text
          v-for="(notification, index) in notifications"
          v-else
          :key="index"
        >
          <div class="d-flex align-items-top py-2 notification text-sm">
            <div
              class="notification-icon d-flex align-items-center justify-content-center mr-4"
            >
              <z-icon
                v-if="notification.event"
                :name="notifiactionIcon(notification.event)"
                :size="18"
              />
              <z-icon v-else name="Notification" :size="18" />
            </div>
            <div>
              <div class="notification-body font-weight-bold">
                {{ notification.title }}
              </div>
              <div>
                {{
                  notification.message
                    ? notification.message
                    : notification.title
                }}
              </div>
              <div class="d-flex justify-content-between w-100 mt-1">
                <div class="notification-date ">
                  Hace
                  {{
                    notification.date
                      ? timeSinceDate(notification.date)
                      : timeSinceDate(notification.createdAt)
                  }}
                </div>
                <div
                  v-if="notification.serviceId"
                  class="notification-id"
                  @click="viewServiceDetail(notification)"
                >
                  ID Orden {{ zubutId(notification.serviceId) }}
                </div>
              </div>
            </div>
          </div>
        </b-dropdown-text>
        <div class="text-center my-2">
          <z-button
            v-if="limit < options.length"
            variant="secondary"
            class="showMore"
            @click="showMoreNotifications"
          >
            Cargar más
          </z-button>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import activity from "@zubut/common/src/constants/logs/activity";
import serviceType from "@zubut/common/src/constants/services/type";
import formatDistance from "date-fns/formatDistance";
import parseISO from "date-fns/parseISO";
import es from "date-fns/locale/es";
import { makeShortUUID } from "@/utils/strings";
import * as mutations from "@/store/modules/notification/mutations-types";

export default {
  name: "NotificationsDropdown",

  data() {
    return {
      options: [],
      loading: false,
      limit: 5
    };
  },

  computed: {
    notifications() {
      return this.options.slice(0, this.limit);
    },

    areNotifications() {
      return this.options.length === 0;
    },

    hasNewNotifications: {
      get() {
        return this.$store.state.notification.hasNewNotifications;
      },
      set(val) {
        this.$store.commit(
          `notification/${mutations.UPDATE_HAS_NEW_NOTIFICATIONS}`,
          val
        );
      }
    }
  },

  mounted() {
    this.initializeNotifications();
  },

  methods: {
    notifiactionIcon(event) {
      switch (event) {
        case activity.NUM_CHARGE_ATTEMPT:
        case activity.NUM_CONTACT_ATTEMPT:
        case activity.NUM_EXTRA_HOURS:
        case activity.NUM_EXTRA_HOURS_APPROVAL:
        case activity.NUM_SIGNATURE_OMISSION:
        case activity.NUM_PACKAGE_RETURN:
        case activity.NUM_SERVICE_CANCELED:
          return "Alert";
        case activity.NUM_WALLET_PAYMENT:
        case activity.NUM_CREDIT_PAYMENT:
        case activity.NUM_CARD_PAYMENT:
        case activity.NUM_REGISTER_COMMENT:
        case activity.NUM_BILLING_DATA_UPDATED:
        case activity.NUM_BILLING_DATA_CAPTURE_OMITTED:
        case activity.NUM_EXPRESS_SERVICE_CREATED:
        case activity.NUM_SERVICE_ARCHIVED:
        case activity.NUM_DELIVERY_OUT_OF_RANGE:
        case activity.NUM_ASSIGNED_BY_EXTERNAL_ID:
        case activity.NUM_PICKUP_ATTEMPT:
          return "Notification";
        case activity.NUM_DELIVERY_ATTEMPT:
        case activity.NUM_RESERVATION_CREATED:
          return "DateHour";
        case activity.NUM_ARRIVED:
        case activity.NUM_DELIVERED:
        case activity.NUM_SERVICE_FINALIZED:
        case activity.NUM_SERVICE_ASSIGNATION:
        case activity.NUM_SERVICE_RELEASED:
          return "Truck";
        case activity.NUM_ONTIME_CHECKIN:
        case activity.NUM_LATE_CHECKIN:
          return "RatePerHour";
        default:
          return "Notification";
      }
    },
    viewServiceDetail(notification) {
      const serviceRouter = this.serviceRouter(notification.serviceType);
      if (serviceRouter !== "")
        if (notification)
          this.$router
            .push({
              name: serviceRouter,
              params: { id: notification.serviceId }
            })
            .catch(() => {});
    },

    serviceRouter(type) {
      if (type === serviceType.NUM_RENT_PER_HOUR) return "reservationDetail";
      if (serviceType.isMultiPoint(type)) return "serviceDetail";
      return "";
    },

    async initializeNotifications() {
      this.loading = true;
      this.$store
        .dispatch("notification/getNotifications", {
          filter: { limit: 20 }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getNotifications() {
      this.options = [];
      this.options = this.$store.getters["notification/notifications"];
      this.hasNewNotifications = false;
    },

    showMoreNotifications() {
      this.limit += 5;
    },

    timeSinceDate(currentDate) {
      return formatDistance(parseISO(currentDate), Date.now(), {
        locale: es
      }).replace("alrededor de", "");
    },

    zubutId(id) {
      return makeShortUUID(id);
    },

    eventName(event) {
      return activity.get[event];
    },

    eraseNotifications() {
      this.options = [];
      this.$store.dispatch("notification/deleteNotifications");
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $solitude;

  border-radius: 0.6em;
  background-color: $white;
  width: 54px;
  height: 56px;
}

.notification {
  min-width: 6rem;
}

.notification-body {
  width: 230px;
}

.notification-icon {
  background-color: $ghost-white;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.notification-date {
  color: $comet;
}

.notification-id {
  color: $dodger-blue;
}

.dropdown {
  margin: 0;
}

::v-deep .dropdown-toggle {
  height: 56px !important;
  width: 54px !important;
  background-color: rgba(white, 0.1) !important;
  margin-right: 1rem;
  padding: 0;

  &:active,
  &:focus,
  &:focus-visible {
    box-shadow: none !important;
    outline: none;
  }
}

::v-deep .dropdown-menu {
  right: 40px !important;
  min-width: 320px !important;
  max-height: 520px;
  overflow: auto;
  box-shadow: 0 3px 9px 3px rgba($tangaroa, 0.2);
  border-radius: 10px;
  border: none;
  transform: translateX(-232px) translateY(45px) !important;
}

@include media-breakpoint-up(md) {
  ::v-deep .dropdown-menu {
    transform: translatex(-28px) translateY(45px) !important;
  }
}

.btn {
  padding: 0;
}
::v-deep .btn-secondary {
  border: none;

  &:active,
  &:focus,
  &:focus-visible {
    box-shadow: none;
    outline: none;
  }
}

.showMore {
  width: 80%;
}

.notification-dropdown-icon {
  position: relative;
}

.notification-dropdown-icon.active::before {
  position: absolute;
  content: "";
  top: 5px;
  right: -5px;
  width: 10px;
  height: 10px;
  border: 2px solid $white;
  background-color: $tomato;
  border-radius: 100%;
  z-index: 100;
}
</style>
