const EXPRESS_SERVICE_CREATED = "Creación de entrega express";
const ARRIVED = "Registro de llegada a destino";
const DELIVERED = "Entrega en destino realizada";
const SERVICE_FINALIZED = "Servicio finalizado";
const SERVICE_ASSIGNATION = "Asignación de servicio";
const SERVICE_RELEASED = "Servicio liberado";
const SERVICE_CANCELED = "Cancelación de servicio";
const SERVICE_ARCHIVED = "Servicio archivado";
const DELIVERY_OUT_OF_RANGE = "Entrega fuera de rango";
const WALLET_PAYMENT = "Pago con Wallet";
const CREDIT_PAYMENT = "Pago a crédito";
const CARD_PAYMENT = "Pago con tarjeta";
const DELIVERY_ATTEMPT = "Intento de entrega";
const CHARGE_ATTEMPT = "Intento de cobro a tarjeta o wallet";
const CONTACT_ATTEMPT = "Intento de contacto al Cliente";
const RESERVATION_CREATED = "Creación de reservación";
const ONTIME_CHECKIN = "Check-in en sucursal a tiempo";
const LATE_CHECKIN = "Check-in en sucursal +20min tarde*";
const EXTRA_HOURS = "Registro de horas extras";
const EXTRA_HOURS_APPROVAL = "Aprobación de horas extras";
const SIGNATURE_OMISSION = "Omisión de firma al entregar";
const ASSIGNED_BY_EXTERNAL_ID = "Asignar por externalID";
const PICKUP_ATTEMPT = "Intento de recolección";
const REGISTER_COMMENT = "Registro de comentario";
const PACKAGE_RETURN = "Retorno de paquete";
const BILLING_DATA_UPDATED = "Datos de facturación actualizados";
const BILLING_DATA_CAPTURE_OMITTED = "Captura de datos de facturación omitida";

const NUM_EXPRESS_SERVICE_CREATED = 0;
const NUM_ARRIVED = 1;
const NUM_DELIVERED = 2;
const NUM_SERVICE_FINALIZED = 3;
const NUM_SERVICE_ASSIGNATION = 4;
const NUM_SERVICE_RELEASED = 5;
const NUM_SERVICE_CANCELED = 6;
const NUM_SERVICE_ARCHIVED = 7;
const NUM_DELIVERY_OUT_OF_RANGE = 8;
const NUM_WALLET_PAYMENT = 9;
const NUM_CREDIT_PAYMENT = 10;
const NUM_CARD_PAYMENT = 11;
const NUM_DELIVERY_ATTEMPT = 12;
const NUM_CHARGE_ATTEMPT = 13;
const NUM_CONTACT_ATTEMPT = 14;
const NUM_RESERVATION_CREATED = 15;
const NUM_ONTIME_CHECKIN = 16;
const NUM_LATE_CHECKIN = 17;
const NUM_EXTRA_HOURS = 18;
const NUM_EXTRA_HOURS_APPROVAL = 19;
const NUM_SIGNATURE_OMISSION = 20;
const NUM_ASSIGNED_BY_EXTERNAL_ID = 21;
const NUM_PICKUP_ATTEMPT = 22;
const NUM_REGISTER_COMMENT = 23;
const NUM_PACKAGE_RETURN = 24;
const NUM_BILLING_DATA_UPDATED = 25;
const NUM_BILLING_DATA_CAPTURE_OMITTED = 26;

const get = {};
get[NUM_EXPRESS_SERVICE_CREATED] = EXPRESS_SERVICE_CREATED;
get[NUM_ARRIVED] = ARRIVED;
get[NUM_DELIVERED] = DELIVERED;
get[NUM_SERVICE_FINALIZED] = SERVICE_FINALIZED;
get[NUM_SERVICE_ASSIGNATION] = SERVICE_ASSIGNATION;
get[NUM_SERVICE_RELEASED] = SERVICE_RELEASED;
get[NUM_SERVICE_CANCELED] = SERVICE_CANCELED;
get[NUM_SERVICE_ARCHIVED] = SERVICE_ARCHIVED;
get[NUM_DELIVERY_OUT_OF_RANGE] = DELIVERY_OUT_OF_RANGE;
get[NUM_WALLET_PAYMENT] = WALLET_PAYMENT;
get[NUM_CREDIT_PAYMENT] = CREDIT_PAYMENT;
get[NUM_CARD_PAYMENT] = CARD_PAYMENT;
get[NUM_DELIVERY_ATTEMPT] = DELIVERY_ATTEMPT;
get[NUM_CHARGE_ATTEMPT] = CHARGE_ATTEMPT;
get[NUM_CONTACT_ATTEMPT] = CONTACT_ATTEMPT;
get[NUM_RESERVATION_CREATED] = RESERVATION_CREATED;
get[NUM_ONTIME_CHECKIN] = ONTIME_CHECKIN;
get[NUM_LATE_CHECKIN] = LATE_CHECKIN;
get[NUM_EXTRA_HOURS] = EXTRA_HOURS;
get[NUM_EXTRA_HOURS_APPROVAL] = EXTRA_HOURS_APPROVAL;
get[NUM_SIGNATURE_OMISSION] = SIGNATURE_OMISSION;
get[NUM_ASSIGNED_BY_EXTERNAL_ID] = ASSIGNED_BY_EXTERNAL_ID;
get[NUM_PICKUP_ATTEMPT] = PICKUP_ATTEMPT;
get[NUM_REGISTER_COMMENT] = REGISTER_COMMENT;
get[NUM_PACKAGE_RETURN] = PACKAGE_RETURN;
get[NUM_BILLING_DATA_UPDATED] = BILLING_DATA_UPDATED;
get[NUM_BILLING_DATA_CAPTURE_OMITTED] = BILLING_DATA_CAPTURE_OMITTED;

const options = Object.keys(get).map(key => ({
  text: get[key],
  value: parseInt(key)
}));

export default {
  options,
  get,
  EXPRESS_SERVICE_CREATED,
  ARRIVED,
  DELIVERED,
  SERVICE_FINALIZED,
  SERVICE_ASSIGNATION,
  SERVICE_RELEASED,
  SERVICE_CANCELED,
  SERVICE_ARCHIVED,
  DELIVERY_OUT_OF_RANGE,
  WALLET_PAYMENT,
  CREDIT_PAYMENT,
  CARD_PAYMENT,
  DELIVERY_ATTEMPT,
  CHARGE_ATTEMPT,
  CONTACT_ATTEMPT,
  RESERVATION_CREATED,
  ONTIME_CHECKIN,
  LATE_CHECKIN,
  EXTRA_HOURS,
  EXTRA_HOURS_APPROVAL,
  SIGNATURE_OMISSION,
  ASSIGNED_BY_EXTERNAL_ID,
  PICKUP_ATTEMPT,
  REGISTER_COMMENT,
  PACKAGE_RETURN,
  BILLING_DATA_UPDATED,
  BILLING_DATA_CAPTURE_OMITTED,
  NUM_EXPRESS_SERVICE_CREATED,
  NUM_ARRIVED,
  NUM_DELIVERED,
  NUM_SERVICE_FINALIZED,
  NUM_SERVICE_ASSIGNATION,
  NUM_SERVICE_RELEASED,
  NUM_SERVICE_CANCELED,
  NUM_SERVICE_ARCHIVED,
  NUM_DELIVERY_OUT_OF_RANGE,
  NUM_WALLET_PAYMENT,
  NUM_CREDIT_PAYMENT,
  NUM_CARD_PAYMENT,
  NUM_DELIVERY_ATTEMPT,
  NUM_CHARGE_ATTEMPT,
  NUM_CONTACT_ATTEMPT,
  NUM_RESERVATION_CREATED,
  NUM_ONTIME_CHECKIN,
  NUM_LATE_CHECKIN,
  NUM_EXTRA_HOURS,
  NUM_EXTRA_HOURS_APPROVAL,
  NUM_SIGNATURE_OMISSION,
  NUM_ASSIGNED_BY_EXTERNAL_ID,
  NUM_PICKUP_ATTEMPT,
  NUM_REGISTER_COMMENT,
  NUM_PACKAGE_RETURN,
  NUM_BILLING_DATA_UPDATED,
  NUM_BILLING_DATA_CAPTURE_OMITTED
};
