<template>
  <b-dropdown
    ref="dropdown"
    class="z-dropdown"
    :boundary="boundary"
    :class="{
      active: clicked,
      expanded,
      'variant-default': variant === 'default',
      'variant-white': variant === 'white',
      'variant-light': variant === 'light',
      'variant-error': variant === 'error',
      rounded: rounded,
      webapp: type === 'webapp'
    }"
    variant="light"
    :block="block"
    :lazy="lazy"
    :no-caret="noCaret"
    :no-flip="noFlip"
    v-bind="$attrs"
    @show="handleShow"
    v-on="$listeners"
  >
    <template #button-content>
      <slot
        name="button-content"
        :title="name"
        :option="selectedOptionText"
        :option-data="optionData"
      >
        <span class="z-wrap-text">
          {{ currentText }}
        </span>
      </slot>
    </template>
    <slot name="header">
      <div v-if="search">
        <div :class="{ 'pb-2': !hasEmptyResults }">
          <z-search-input
            v-model="searchVal"
            :placeholder="searchPlaceholder"
          />
        </div>
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center pt-5"
        >
          <loading-spinner />
        </div>
        <div
          v-show="hasEmptyResults && !loading"
          class="pb-2 pt-3 small text-center"
        >
          No hay resultados
        </div>
      </div>
      <b-dropdown-header v-else-if="showHeader">
        Mostrar
      </b-dropdown-header>
    </slot>
    <div v-show="!loading" data-test-id="dropdown-options">
      <template>
        <b-dropdown-item-button
          v-for="option in optionsFiltered"
          :key="`${$attrs.id}-${option.value}`"
          :active="value === option.value"
          :disabled="option.disabled ? option.disabled : false"
          data-test-id="dropdown-option"
          @click="selectOption(option)"
        >
          <!-- TODO: Value comparison function as prop  -->
          <div
            v-if="value === option.value"
            class="d-flex align-items-center justify-content-between "
          >
            <slot name="option" :option="option">
              {{ option.text }}
              <font-awesome-icon class="selected-icon ml-3" icon="approve" />
            </slot>
          </div>
          <div v-else>
            <slot name="option" :option="option">
              {{ option.text }}
            </slot>
          </div>
        </b-dropdown-item-button>
        <b-dropdown-item-button
          v-if="!hasCurrentOption && selectedOption"
          active
        >
          <div
            class="active-option d-flex align-items-center justify-content-between "
          >
            {{ selectedOption.text }}
            <font-awesome-icon class="selected-icon" icon="approve" />
          </div>
        </b-dropdown-item-button>
      </template>
    </div>
  </b-dropdown>
</template>
<script>
import ZSearchInput from "./ZSearchInput";
export default {
  name: "ZDropdown",
  components: {
    ZSearchInput
  },
  props: {
    boundary: {
      type: String,
      default: "scrollParent"
    },
    block: {
      type: Boolean,
      default: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: true
    },
    maxHeight: {
      type: String,
      default: "400px"
    },
    name: {
      type: String,
      default: ""
    },
    noCaret: {
      type: Boolean,
      default: false
    },
    noFlip: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: [Number, String, Boolean],
      default: null
    },
    rounded: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: "Buscar"
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    variant: {
      type: String,
      default: "default",
      validator: val => ["default", "white", "light", "error"].indexOf(val) > -1
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "admin",
      validator: val => ["admin", "webapp"].indexOf(val) > -1
    }
  },
  data() {
    return {
      clicked: false,
      searchVal: "",
      selectedOption: null
    };
  },
  computed: {
    currentText() {
      if (this.selectedOptionText) {
        const prependedText = this.name ? `${this.name}:` : "";
        return `${prependedText} ${this.selectedOptionText}`;
      }
      return this.placeholder || "";
    },
    optionsFiltered() {
      if (this.search && this.searchVal) {
        return this.options.filter(
          item =>
            item.text.toLowerCase().indexOf(this.searchVal.toLowerCase()) !== -1
        );
      }
      return this.options;
    },
    hasEmptyResults() {
      return !this.optionsFiltered.length;
    },
    hasCurrentOption() {
      return this.options.find(op => this.value === op?.value);
    },
    showOptions() {
      if (this.selectedOption) {
        return this.hasCurrentOption;
      }
      return true;
    },
    selectedOptionText() {
      return this.hasCurrentOption
        ? this.hasCurrentOption?.text
        : this.selectedOption?.text || "";
    },
    optionData() {
      return this.hasCurrentOption || this.selectedOption;
    }
  },
  watch: {
    maxHeight: {
      handler() {
        this.updateMaxHeight();
      }
    },
    searchVal: {
      immediate: true,
      handler(newVal) {
        if (this.search) {
          this.$emit("search-change", newVal);
        }
      }
    },
    hasCurrentOption(newVal) {
      if (newVal) {
        this.selectedOption = { ...newVal };
      }
    }
  },
  mounted() {
    this.updateMaxHeight();
  },
  methods: {
    updateMaxHeight() {
      const dropdownMenu = this.$el.querySelector(".dropdown-menu");
      if (dropdownMenu) {
        dropdownMenu.style.maxHeight = this.maxHeight;
      }
    },
    selectOption(option) {
      this.$emit("input", option.value);
      this.$emit("option-change", option);
      this.selectedOption = option;
    },
    handleShow() {
      this.clicked = true;
      this.$emit("show");
    },
    hide() {
      this.$refs.dropdown.hide();
    }
  }
};
</script>
<style lang="scss">
@import "../styles/variables/colors.scss";
.z-dropdown {
  .z-wrap-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .assign-button {
    color: $zubut-blue;
  }
  .dropdown-toggle {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dim-gray;
    text-align: left;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    min-height: 32px;
    &:hover,
    &:focus {
      box-shadow: none;
      background-color: rgba($free-speech-blue, 0.07);
      border-color: transparent;
    }
    &:visited {
      color: $nero;
    }
    &::after {
      font-size: 1.4em;
      color: $nero;
    }
  }
  &.variant-default {
    .dropdown-toggle {
      border-color: transparent;
      background-color: $ghost-white;
    }
    &.active .dropdown-toggle {
      color: $nero;
    }
    &.show .dropdown-toggle {
      color: $nero;
      background-color: rgba($free-speech-blue, 0.07);
      border-color: $zubut-blue;
    }
  }
  &.variant-white {
    .dropdown-toggle {
      border-color: $link-water;
      background-color: $white;
    }
  }
  &.variant-error {
    .dropdown-toggle {
      border-color: transparent;
      background-color: $tutu;
    }
    &.active .dropdown-toggle {
      color: $medium-carmine;
      background-color: $tutu;
    }
    &.show .dropdown-toggle {
      color: $medium-carmine;
      background-color: $tutu;
    }
  }
  &.rounded {
    .dropdown-toggle {
      border-radius: 6px !important;
    }
  }
  &.variant-light {
    .dropdown-toggle {
      border-color: $solitude;
      background-color: $white;
      &:hover,
      &:focus {
        border-color: $solitude;
        background-color: $white;
      }
    }
  }
  &.expanded .dropdown-menu {
    width: 100%;
  }
  .dropdown-menu {
    padding-left: 0.5em;
    padding-right: 0.5em;
    border: none;
    box-shadow: 0 3px 9px 1px rgba($tangaroa, 0.2);
    overflow-y: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    .dropdown-item {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      &:hover,
      &.active,
      &:active,
      &:focus {
        color: $nero;
        background-color: rgba($royal-blue, 0.06);
      }
      &.active .selected-icon {
        color: $dodger-blue;
      }
      &:focus {
        background-color: rgba($royal-blue, 0.096);
        outline: none;
      }
    }
  }
  .dropdown-header {
    padding-left: 1.4em;
    padding-right: 1.4em;
  }
}
.webapp {
  .dropdown-toggle {
    border-radius: 6px;
  }
  .dropdown-menu {
    font-size: 12px;
  }
}
</style>
