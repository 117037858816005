<template>
  <z-menu-dropdown
    class="z-menu-dropdown-cities"
    :options="options"
    :loading="loading"
    :name="name"
    :value="value"
    v-bind="$attrs"
    @input="$emit('input', $event)"
  />
</template>

<script>
import ZMenuDropdown from "./ZMenuDropdown";

export default {
  name: "ZMenuDropdownCities",

  components: {
    ZMenuDropdown
  },

  props: {
    name: {
      type: String,
      default: "Ciudad"
    },
    value: {
      type: [Number, String],
      default: null
    }
  },

  data() {
    return {
      loading: true
    };
  },

  computed: {
    options() {
      return this.$store.getters["cities/cityOptions"];
    }
  },

  created() {
    this.getOptions();
  },

  methods: {
    getOptions() {
      this.loading = true;
      this.$store.dispatch("cities/getCities").finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>
