<template>
  <div class="z-search-input d-flex flex-row align-items-center pl-3">
    <z-icon name="Search" :size="16" />
    <b-form-input
      v-bind="$attrs"
      autofocus
      trim
      :value="value"
      type="search"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import ZIcon from "./ZIcon";

export default {
  name: "ZSearchInput",

  components: {
    ZIcon
  },

  props: {
    value: {
      type: String,
      required: false,
      default: ""
    }
  },

  data() {
    return {
      inputVal: this.value
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

.z-search-input {
  cursor: text;
  background-color: $white;
  border: 1px solid $gainsboro;
  border-radius: 6px;

  .icon {
    color: $nobel;
  }

  input {
    cursor: text;
    color: $comet;
    min-height: 32px;
    font-size: 12px;
    border-color: transparent;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
</style>
