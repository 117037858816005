<template>
  <div class="z-menu">
    <nav class="z-navbar d-flex align-items-center justify-content-between">
      <router-link :to="{ name: 'dashboard' }">
        <img
          v-if="isZubut"
          class="z-logo"
          src="@/assets/img/zubut-logo-blue.png"
        />
        <img v-else class="z-logo" src="@/assets/img/mu-logo_357x574.png" />
      </router-link>
      <div class="d-flex align-items-md-center">
        <div v-if="showCitiesDropdown" class="mr-md-3 d-none d-md-block">
          <z-menu-dropdown-cities v-model="selectedCity" />
        </div>

        <!-- TODO: Connect to new stats screen -->
        <!-- <div class="z-nav-btn stats" role="button">
          <z-icon name="Stats" />
        </div> -->

        <notifications-dropdown />

        <div class="z-nav-btn client-profile p-md-0 mr-0">
          <b-dropdown
            class="z-client-dropdown"
            variant="link"
            size="lg"
            no-caret
            dropup
            lazy
          >
            <template slot="button-content">
              <div>
                <div class="d-md-none">
                  <font-awesome-icon icon="user" size="sm" />
                </div>
                <div class="d-none d-md-flex align-items-center">
                  <div class="d-flex mr-3">
                    <div class="profile-image">
                      <font-awesome-icon icon="user" size="sm" />
                    </div>
                    <div>
                      <div class="font-weight-bold text-charcoal">
                        {{ clientName }}
                      </div>
                      <div class="font-weight-semi-bold text-grey">
                        {{ clientTypeText }}
                      </div>
                    </div>
                  </div>
                  <div class="chevron-icon">
                    <z-icon name="ChevronDown" :size="20" />
                  </div>
                </div>
              </div>
            </template>
            <b-dropdown-item class="d-md-none px-2">
              <div>
                <div class="font-weight-bold text-charcoal">
                  {{ clientName }}
                </div>
                <div class="font-weight-semi-bold text-grey">
                  {{ clientTypeText }}
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'profile' }">
              <div class="z-menu-option d-flex align-items-center">
                <z-icon name="Profile" size="18" view-box="0 0 26 26" /> Perfil
              </div>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'addresses' }">
              <div class="z-menu-option d-flex align-items-center">
                <z-icon name="FavoriteAddress" size="18" /> Direcciones
                favoritas
              </div>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'deliveries' }">
              <div class="z-menu-option d-flex align-items-center">
                <z-icon name="Truck" size="18" view-box="0 0 26 26" />
                Historial de entregas
              </div>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'payment-methods' }">
              <div class="z-menu-option d-flex align-items-center">
                <z-icon name="Wallet" size="18" /> Métodos de pago
              </div>
            </b-dropdown-item>
            <b-dropdown-item v-if="isZubut" :to="{ name: 'billing' }">
              <div class="z-menu-option d-flex align-items-center">
                <z-icon name="Billing" size="18" view-box="0 0 26 26" />
                Facturación
              </div>
            </b-dropdown-item>
            <b-dropdown-item class="border-top mt-2 pt-2" @click="logout()">
              <div class="z-menu-option d-flex align-items-center">
                <z-icon name="Logout" size="16" /> Cerrar sesión
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import ZIcon from "@zubut/common/src/components/ZIcon";
import ZMenuDropdownCities from "@/app/components/ZMenuDropdownCities";
import NotificationsDropdown from "./NotificationsDropdown";
import * as mutation from "@/store/modules/cities/mutations-types";

export default {
  name: "ZMenu",

  components: {
    ZIcon,
    ZMenuDropdownCities,
    NotificationsDropdown
  },

  data() {
    return {
      isLoggingOut: false,
      showClientMenu: false
    };
  },

  computed: {
    clientName() {
      return this.$store.state.user.contact;
    },
    clientTypeText() {
      return this.$store.getters["user/clientTypeText"];
    },
    showCitiesDropdown() {
      return this.$store.getters["user/isCompany"];
    },
    selectedCity: {
      get() {
        return this.$store.state.cities.selectedCity;
      },
      set(val) {
        this.$store.commit(`cities/${mutation.SET_SELECTED_CITY}`, val);
      }
    },
    isZubut() {
      return this.$store.getters["isZubut"];
    }
  },

  methods: {
    logout() {
      if (!this.isLoggingOut) {
        this.isLoggingOut = true;
        this.$store
          .dispatch("auth/logout", true)
          .then(() => {
            this.isLoggingOut = false;
            // TODO: Move this to logout action
            this.$store.commit("reset");
            this.$store.commit("auth/reset");
            this.$store.commit("rents/reset");
            this.$store.dispatch("services/reset");
            this.$store.commit("request/reset");
            this.$store.dispatch("user/reset");
            this.$store.commit("express/RESET");
            this.$router.push({ name: "login" });
          })
          .catch(err => {
            this.$captureError(err);
            this.isLoggingOut = false;
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.z-menu {
  height: 100%;
  background: $white;
  border-bottom: 1px solid $solitude;
}

.z-navbar {
  padding: 1rem;
  height: 100%;
}

.z-nav-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;
  border-radius: 0.4em;
  margin-right: 0.8rem;

  &:last-child {
    margin-right: 0;
  }
}

.client-profile {
  padding: 0rem;
  border-radius: 0.4em;
  background-color: $solitude;
  position: relative;
  color: $charcoal;
}

::v-deep .client-profile .dropdown-item {
  color: $charcoal;
}

::v-deep .client-profile .dropdown-toggle {
  color: $charcoal;
}

.z-logo {
  height: 24px;
}

.client-menu {
  position: absolute;
  min-width: 200px;
  top: 58px;
  right: 0px;
  background: $white;
  padding: 1rem;
  box-shadow: 0px 0px 4px $solitude;
  border: 1px solid $solitude;
  border-radius: 1rem;
  z-index: 100;

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
}

::v-deep .z-client-dropdown {
  .assign-button {
    color: var(--primary);
  }

  .dropdown-toggle {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dim-gray;
    text-align: left;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    min-height: 32px;

    &:hover,
    &:focus {
      box-shadow: none;
      border-color: transparent;
      text-decoration: none;
    }

    &:visited {
      color: $nero;
    }

    &::after {
      font-size: 1.4em;
      color: $nero;
    }
  }

  .dropdown-menu {
    padding: 8px 0;
    margin-top: 8px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 3px 9px 1px rgba($tangaroa, 0.2);
    overflow-y: scroll;
    transform: translate3d(-182px, 34px, 0px);

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    .dropdown-item {
      padding: 0 8px;

      .z-menu-option {
        font-size: 12px;
        padding: 8px 24px 8px 4px;

        svg {
          margin-right: 16px;
        }
      }

      &:hover,
      &.active,
      &:active,
      &:focus {
        color: $nero;
        background-color: white;
        .z-menu-option {
          color: $nero;
          background-color: var(--primary-bg);

          svg g {
            fill: var(--primary);
          }
        }
      }

      &.active .selected-icon {
        color: $dodger-blue;
      }

      &:focus {
        background-color: rgba($royal-blue, 0.096);
        outline: none;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .z-menu {
    background: transparent;
    border-bottom: none;
  }

  .z-navbar {
    padding: 2rem 2rem 2rem 3.3rem;
  }

  .z-logo {
    height: 36px;
  }

  ::v-deep .z-client-dropdown .dropdown-toggle {
    padding-left: 0.6rem;
  }

  .z-nav-btn {
    border: 1px solid $solitude;
    padding: 1.2rem;
    margin-right: 1rem;
    border-radius: 0.6em;
    background-color: $white;

    &:last-child {
      margin-right: 0rem;
    }

    &.client-profile {
      background-color: $white;
      padding: 0.6rem;

      .profile-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin-right: 1rem;
        background-color: $solitude;
        border-radius: 0.6em;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .z-navbar {
    padding: 2.2rem 2rem 2.2rem 3.3rem;
  }
}
</style>
