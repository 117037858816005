<template>
  <transition name="fade">
    <div v-if="showInstallMessage" class="pwa-prompt" @click.self="closePrompt">
      <div class="pwa-prompt-body py-3 bg-white text-center">
        <div class="position-relative">
          <zubut-icon class="app-icon" />
        </div>
        <div class="px-4 py-2">
          <div class="app-name font-weight-bold">Instala Zubut</div>
          <div class="install-message text-lg mt-2">
            Instala la aplicación para acceso rápido. No necesitas Appstore, ni
            descargar.
          </div>
        </div>
        <hr />
        <div class="py-2 px-5">
          <div class="text-left mb-3">
            <span class="text-main font-weight-semi-bold mr-2">
              Paso 1
            </span>
            Presiona
            <img
              src="@/assets/img/share-icon-iphone.jpeg"
              class="install-icon"
            />
          </div>
          <div class="text-left">
            <span class="text-main font-weight-semi-bold mr-2">
              Paso 2
            </span>
            <span> 'Add to Home Screen'</span>
          </div>
        </div>
        <hr />
        <div class="px-4">
          <z-button
            variant="primary-alt"
            class="font-weight-bold w-100"
            @click="closePrompt"
          >
            En otro momento
          </z-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ZubutIcon from "@/assets/icons/zubut-blue-bg.svg";

export default {
  name: "PwaPromptIos",

  components: {
    ZubutIcon
  },

  data() {
    return {
      showInstallMessage: false
    };
  },

  created() {
    this.showPrompt();
  },

  methods: {
    closePrompt() {
      this.showInstallMessage = false;
      localStorage.showInstallMessage = false;
    },
    showPrompt() {
      const showInstallMessage = localStorage.showInstallMessage !== "false";
      // Detect if user already opted out of installation
      if (showInstallMessage !== false) {
        // Detects if device is on iOS
        const isIos = () => {
          const userAgent = window.navigator.userAgent.toLowerCase();
          return /iphone|ipad|ipod/.test(userAgent);
        };

        // Detects if device is already instaleld as a PWA
        const isInStandaloneMode = () =>
          "standalone" in window.navigator && window.navigator.standalone;

        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode()) {
          this.showInstallMessage = true;
        } else {
          this.showInstallMessage = false;
        }

        localStorage.showInstallMessage = this.showInstallMessage;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pwa-prompt {
  position: fixed;
  z-index: 20;
  width: 100vw;
  background-color: rgba(100, 100, 100, 0.25);
  display: flex;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.pwa-prompt-body {
  width: 370px;
  border-radius: 20px;
  box-shadow: 0px 8px 16px 0px rgba($black, 0.2);
}

.app-icon {
  position: absolute;
  width: 90px;
  height: 90px;
  top: -60px;
  margin-left: -45px;
  box-shadow: 0px 0px 0px 4px white;
  border-radius: 20px;
}

.app-name {
  margin-top: 50px;
  font-size: 22px;
}

.install-icon {
  height: 16px;
  margin-left: 4px;
  margin-top: -4px;
}

.install-message {
  line-height: 20px;
}

.font-apple-system {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
