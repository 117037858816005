<template>
  <div class="z-menu-dropdown">
    <z-dropdown
      id="dropdown-role-type"
      :value="value"
      :name="name"
      class="z-menu-dropdown z-dropdown"
      :options="options"
      :show-header="false"
      :loading="loading"
      variant="light"
      @input="$emit('input', $event)"
    >
      <template #button-content="{ title, option }">
        <slot name="prepend"> </slot>
        <div>
          <div class="z-menu-dropdown-title">{{ title }}</div>
          <div class="z-menu-dropdown-option">{{ option }}</div>
          <div class="chevron-icon">
            <z-icon name="ChevronDown" :size="20" />
          </div>
        </div>
      </template>
    </z-dropdown>
  </div>
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZIcon from "@zubut/common/src/components/ZIcon";

export default {
  name: "ZMenuDropdown",

  components: {
    ZDropdown,
    ZIcon
  },

  props: {
    name: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: [Number, String],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.z-menu-dropdown {
  height: 80px;
}

::v-deep .z-dropdown .dropdown-toggle {
  background-color: $white;
  border-radius: 0.6em;
  border: 1px solid $solitude;
  height: 100%;
  padding: 1rem 1.4rem;
}

::v-deep .z-dropdown .dropdown-toggle::after {
  color: transparent;
}

.z-menu-dropdown-title {
  color: $charcoal;
  font-size: 1.1em;
  font-weight: 700;
}

.z-menu-dropdown-option {
  color: $grey;
}

.chevron-icon {
  position: absolute;
  right: 1.4rem;
  top: 50%;
  margin-top: -10px;
}

@include media-breakpoint-up(md) {
  .z-menu-dropdown {
    height: 60px;
  }

  ::v-deep .z-dropdown .dropdown-toggle {
    padding-right: 3rem;
  }
}
</style>
